@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./font-inter.css";
@import "./font-roboto.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, Roboto, "Helvetica Neue", sans-serif;
}

.clickable {
  cursor: pointer;
}

/*SVG ICON SYSTEM*/
/*More info: https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4*/
.icon {
  display: inline-flex;
  align-self: center;

  svg,
  img {
    height: 1em;
    width: 1em;
    fill: currentColor;
  }
}

.icon.baseline svg,
.icon.baseline img {
  top: 0.125em;
  position: relative;
}
