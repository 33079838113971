@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url(assets/Roboto/Roboto-italic-100.ttf) format("truetype"),
    url(assets/Roboto/Roboto-italic-100.woff2) format("woff2"),
    url(assets/Roboto/Roboto-italic-100.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url(assets/Roboto/Roboto-italic-300.ttf) format("truetype"),
    url(assets/Roboto/Roboto-italic-300.woff2) format("woff2"),
    url(assets/Roboto/Roboto-italic-300.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url(assets/Roboto/Roboto-italic-400.ttf) format("truetype"),
    url(assets/Roboto/Roboto-italic-400.woff2) format("woff2"),
    url(assets/Roboto/Roboto-italic-400.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url(assets/Roboto/Roboto-italic-700.ttf) format("truetype"),
    url(assets/Roboto/Roboto-italic-700.woff2) format("woff2"),
    url(assets/Roboto/Roboto-italic-700.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url(assets/Roboto/Roboto-italic-900.ttf) format("truetype"),
    url(assets/Roboto/Roboto-italic-900.woff2) format("woff2"),
    url(assets/Roboto/Roboto-italic-900.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url(assets/Roboto/Roboto-normal-100.ttf) format("truetype"),
    url(assets/Roboto/Roboto-normal-100.woff2) format("woff2"),
    url(assets/Roboto/Roboto-normal-100.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(assets/Roboto/Roboto-normal-300.ttf) format("truetype"),
    url(assets/Roboto/Roboto-normal-300.woff2) format("woff2"),
    url(assets/Roboto/Roboto-normal-300.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(assets/Roboto/Roboto-normal-400.eot);
  src: local("Roboto"),
    url(assets/Roboto/Roboto-normal-400.ttf) format("truetype"),
    url(assets/Roboto/Roboto-normal-400.svg#Roboto) format("svg"),
    url(assets/Roboto/Roboto-normal-400.eot?#iefix) format("embedded-opentype"),
    url(assets/Roboto/Roboto-normal-400.woff2) format("woff2"),
    url(assets/Roboto/Roboto-normal-400.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(assets/Roboto/Roboto-normal-700.ttf) format("truetype"),
    url(assets/Roboto/Roboto-normal-700.woff2) format("woff2"),
    url(assets/Roboto/Roboto-normal-700.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url(assets/Roboto/Roboto-normal-900.ttf) format("truetype"),
    url(assets/Roboto/Roboto-normal-900.woff2) format("woff2"),
    url(assets/Roboto/Roboto-normal-900.woff) format("woff");
}
